
import React, { useState } from 'react';
import { addAndRemoveDisabledCls, isValidEmail } from '../../utils';

const Footer = () => {
  const [fEmail, setFEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  //
  const submit = async (e: any) => {
    e.preventDefault();

    if (fEmail && isValidEmail(fEmail)) {
        // call api
        const data = {
            email: fEmail,
            name: fEmail,
            phone: '',
            subject: '[Inventiv] New subscribe from ' + fEmail,
            message: `New subscribe from  ${fEmail}`,
            site: 'inventiv.org'
        }
        fetch("https://api.trustyip.com/subcribers/add", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json"
        },
        "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {
            alert('Thank you for contacting us. We will follow up with you soon');
            // reset
            setFEmail('');
            setEmailSent(true);
        })
        .catch(err => {
            alert('There is something wrong. Please try again later!');
            console.log(err);
        });
        //
        addAndRemoveDisabledCls('subscribeBtn');
    } else {
        alert('Please enter your valid email.');
    }

  }

  return (
    <>
      {/* <!-- Subscribe --> */}
      <div className="container">
        <div className="row">
          <div className="co-12 col-md-6 offset-md-3 mt-3">
            <div className="theme-card footerNewsletter wow fadeInUp delay-04s"
              style={{visibility: 'visible', animationName: 'fadeInUp' }}>
              <h6>
                <strong>Subscribe to our Newsletter</strong>
              </h6>

              <form action="">
                <div className="row">
                  <div className="col-12 col-md-8 ">
                    <div className="input-group">
                      <span className="input-group-addon"><i className="fa fa-envelope d-clr"></i></span>
                      <input type="text" className="form-control" placeholder="Your Email" required={true} value={fEmail} onChange={e => setFEmail(e.target.value)}/>
                    </div>
                  </div>
                  <div className="btn-box col-12 col-md-3">
                    <input type="button" className="btn btn-default" id="subscribeBtn" value="Subscribe" onClick={submit}/>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Thank you start --> */}
      <div className="thank-you wow fadeInDown delay-04s">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3">
              <h5 className="w-clr text-left">
                Inventiv Foundation, Inc.
                PO Box 1065 Zephyr Cove,
                NV 89448
              </h5>
              <h6 className="w-clr text-left">
                Tax ID Number: 83-0668793
              </h6>
            </div>
            <div className="col-12 col-md-3">
              <ul>
                <li className="text-left">
                  <a href="/#who-we-are" className="w-clr">About Us</a>
                </li>
                <li className="text-left">
                  <a href="/blog" className="w-clr">News</a>
                </li>
                <li className="text-left">
                  <a href="/contact" className="w-clr">Contact</a>
                </li>
                <li className="text-left">
                  <a href="/inventor-resources" className="w-clr">Inventor Resources</a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-3">
              <ul>
                <li className="text-left">
                  <a href="#" className="w-clr">My Account</a>
                </li>
                <li className="text-left">
                  <a href="#" className="w-clr">Terms of Use</a>
                </li>
                <li className="text-left">
                  <a href="#" className="w-clr">Privacy Policy</a>
                </li>

              </ul>
            </div>
            <div className="col-12 col-md-3 social">
              <ul>
                <li>
                  <a className="" href="#">
                    <i className="fa fa-facebook w-clr"></i>
                  </a>
                </li>
                <li>
                  <a className="" href="#">
                    <i className="fa fa-twitter w-clr"></i>
                  </a>
                </li>
                <li>
                  <a className="" href="#">
                    <i className="fa fa-linkedin w-clr"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Thank you end --> */}
      <a id="page_scroller" href="#top" style={{position: 'fixed', 'zIndex': 2147483647, display: 'none' }}><i
        className="fa fa-chevron-up"></i></a>

      {/* <!-- Full Page Search --> */}
      <div id="full-page-search">
        <button type="button" className="close">×</button>
        <form action="#">
          <input type="search" value="" placeholder="type keyword(s) here" />
          <button type="button" className="btn btn-sm btn-color">Search</button>
        </form>
      </div>
    </>
  )
}

export default Footer
