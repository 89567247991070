import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import Logo from "../../assets/images/inv-logo.png";
import '../../style.css';
import '../../default.css';
////////////////////////////////////////////////////////////////////////////////////////////////////
const HeaderInventiv = ({activeMenu}: any) => {
  return (
    <>
      {/* <div className="page_loader"></div> */}
      <header className="main-header abs-header " id="main-header-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-lg navbar-light rounded">
                <a className="navbar-brand" href="#">
                  <i className="fa fa-facebook"></i>
                </a>
                <a className="navbar-brand" href="#">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a className="navbar-brand" href="#">
                  <i className="fa fa-twitter"></i>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#topnavbar" aria-controls="navbar" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="topnavbar">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarResources"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Resources
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarResources">
                        <li><a className="dropdown-item" href="https://inventiv.org//inventor-resources">Inventor
                          Resources</a></li>
                        <li><a className="dropdown-item" href="https://inventiv.org//probono">USPTO Pro Bono Program</a></li>
                        <li><a className="dropdown-item" href="https://inventiv.org//patent-guide">Patent Guide</a></li>
                        <li><a className="dropdown-item" href="https://inventiv.org//press-releases">Press Releases</a></li>
                      </ul>
                    </li>

                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarPatent" role="button"
                        data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true"
                        aria-expanded="false">
                        Patent FAQs
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarPatent">
                        <li><a className="dropdown-item" href="https://inventiv.org//patentfaqs">Patent FAQs</a></li>
                        <li><a className="dropdown-item" href="https://inventiv.org//patentfaqs/ip-basics">IP Basics</a></li>

                        <li className="dropdown-submenu">
                          <a className="dropdown-item dropdown-toggle" href="#" id="navbarPatentBasics"
                            role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            Patent Basics
                          </a>
                          <ul className="dropdown-menu" aria-labelledby="navbarPatentBasics">
                            <li><a className="dropdown-item" href="https://inventiv.org/patentfaqs/patent-basics">Patent
                              Basics</a></li>
                            <li><a className="dropdown-item"
                              href="https://inventiv.org/patentfaqs/set-up-an-account-with-uspto">Set up an
                              Account with the USPTO</a></li>
                            <li><a className="dropdown-item"
                              href="https://inventiv.org/patentfaqs/need-for-patent-attorney-or-agent">Need
                              for a Patent Attorney or Agent</a></li>
                          </ul>
                        </li>
                        <li className="dropdown-submenu">
                          <a className="nav-link dropdown-toggle" href="#" id="navbarPatentApplication"
                            role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            Provisional Patent Application
                          </a>
                          <ul className="dropdown-menu" aria-labelledby="navbarPatentApplication">
                            <li><a className="dropdown-item"
                              href="https://inventiv.org/patentfaqs/provisional-patent-application">Provisional
                              Patent Application</a></li>
                            <li><a className="dropdown-item"
                              href="https://inventiv.org/patentfaqs/provisional-builder">Provisional
                              Builder</a></li>
                            <li><a className="dropdown-item"
                              href="https://inventiv.org/patentfaqs/after-submit-ppa">After you Submit a
                              PPA</a></li>
                          </ul>
                        </li>
                        <li className="dropdown-submenu">
                          <a className="nav-link dropdown-toggle" href="#"
                            id="navbarUtilityPatentApplication" role="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Utility Patent Application
                          </a>
                          <ul className="dropdown-menu" aria-labelledby="navbarUtilityPatentApplication">
                            <li><a className="dropdown-item"
                              href="https://inventiv.org/patentfaqs/utility-patent-application">Utility Patent
                              Application</a></li>
                            <li><a className="dropdown-item"
                              href="https://inventiv.org/patentfaqs/file-a-utility-patent-application">File a
                              Utility Patent Application</a></li>
                            <li><a className="dropdown-item"
                              href="https://inventiv.org/patentfaqs/what-happens-after-filing-utility-application">What
                              Happens After Filing Utility Application?</a></li>
                          </ul>
                        </li>
                        <li><a className="dropdown-item" href="respond-to-office-action">Respond to
                          Office Actions</a></li>
                        <li><a className="dropdown-item" href="patent-issued">Patent Issued</a></li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link single" href="https://inventiv.org/blog">Blog</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link single" href="https://inventiv.org/join-us">Join Us</a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link single" href="mailto:info@inventiv.org"><i
                        className="fa fa-envelope"></i> Info@inventiv.org</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-lg navbar-light rounded">
                <a className="navbar-brand logo" href="https://inventiv.org/">
                  <img src={Logo} alt="logo" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar"
                  aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbar">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <a className={"nav-link single " + (!activeMenu || activeMenu === 'home' ? 'active' : '')} href="https://inventiv.org/">Home</a>
                    </li>
                    <li className={"nav-item dropdown"}>
                      <a className={"nav-link dropdown-toggle "  + (activeMenu === 'about' ? 'active' : '') } href="#" id="navbarDropdownMenuLink"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        About Us
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li><a className="dropdown-item" href="https://inventiv.org/#who-we-are">Who We Are</a></li>
                        <li><a className="dropdown-item" href="https://inventiv.org/what-we-do">What We Do</a></li>
                        <li><a className="dropdown-item" href="https://inventiv.org/our-history">Our History</a></li>
                        <li><a className="dropdown-item" href="https://inventiv.org/#how-to-help">How to Help</a></li>
                        <li><a className="dropdown-item" href="https://inventiv.org/#where-we-work">Where We Work</a></li>
                        <li><a className="dropdown-item" href="https://inventiv.org/our-science-technology">Our Science &
                          Technology New</a></li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link single " + (activeMenu === 'builder' ? 'active' : '')} href="https://inventiv.org/provisional-builder">Provisional Builder</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link single " + (activeMenu === 'canvas' ? 'active' : '')} href="https://canvas.inventiv.org/">Canvas Builder</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link single " + (activeMenu === 'webinar' ? 'active' : '')} href="https://inventiv.org/webinar">Webinar</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link single " + (activeMenu === 'involved' ? 'active' : '')} href="https://inventiv.org/get-involved">Get Involved</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link single " + (activeMenu === 'contact' ? 'active' : '')} href="https://inventiv.org/contact">Contact</a>
                    </li>
                    {/* <li className="nav-item">
                      <a className="nav-link single" href="https://www.inventiv.org/docket/"><i
                        className="fa fa-lock"></i> Login</a>
                    </li> */}
                    <li className="nav-item">
                      <a className="nav-link donate-button" href="https://inventiv.org/donate">Donate</a>
                    </li>

                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

HeaderInventiv.propTypes = {
  activeMenu: PropTypes.string
}

export default HeaderInventiv;
