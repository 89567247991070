
export function submitACInventivHuge(data: any, message = '') {
  const formData = new FormData();

  // Hidden field key/values.
  formData.append("u", "41");
  formData.append("f", "41");
  formData.append("s", '');
  formData.append("c", "0");
  formData.append("m", "0");
  formData.append("act", "sub");
  formData.append("v", "2");
  formData.append("or", "a253bc1d10ce706885d74aa14bcd2755");

  // Form field key/values.
  formData.append("firstname", data.firstName);
  formData.append("lastname", data.lastName);
  formData.append("email", data.email);
  formData.append("field[21]", data.companyName);

  fetch(`https://patent.activehosted.com/proc.php`, {
    mode: "no-cors",
    method: 'POST',
    body: formData,
  }).then(res => {
    if (message) { alert(message) }
  })
  .catch(err => {
    console.log(err)
    alert('Something wrong. Please try again later!');
  });
}

export const isValidEmail = (email: string) =>{
  return /\S+@\S+\.\S+/.test(email);
}

export const addAndRemoveDisabledCls = (eleId: string) => {
  const ele = document?.getElementById(eleId);
  if (ele) {
    ele.classList.add("disabled");
    // Remove after 3 minutes
    setTimeout(function() {
      const ele = document?.getElementById(eleId);
      if (ele) {
        ele.classList.remove("disabled");
      }
    }, 3 * 60 * 1000);
  }
}
