import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const sectionTitles = [
  "Potential Patentable Claims",
  "Trademark usage and potential trademarks",
  "Copyright usage",
  "Trade secrets"
];
const handleRemoveSpecialCharacter = (str) => {
  return str?.replaceAll("**", "")?.replace(/#/g, "");
};


function CheckIPForm({ prevStep, businessCanvasData, data }) {
  const location = useLocation();
  const navigate = useNavigate();


  const handleBack = () => {
    const state = location.state || {};
    const { formData, step } = state;

    navigate("/", { state: { businessCanvasData, formData, step } });
  };

  // Check if businessCanvasData exists before using it
  const p3Sections = businessCanvasData
    ? Object.keys(businessCanvasData).filter((key) => key.startsWith("p3_"))
    : [];

  return (
    <div className="container">
      <Box component="div">
        {businessCanvasData ? (
          p3Sections.map((p3Section, index) => (
            <Box
              key={index}
              component="div"
              className="main-border"
              mt={6}
              sx={{
                padding: { xs: "0.5rem 8px", sm: "1rem 20px" },
                backgroundColor: "#fff",
                position: "relative"
              }}
            >
              <Box
                component="h2"
                fontWeight={500}
                color="var(--main-content-text-color)"
                mb={2}
              >
                {sectionTitles[index]}
              </Box>
              <Box
                mt={2}
                component="div"
                display="flex"
                justifyContent="flex-start"
                sx={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{ __html: handleRemoveSpecialCharacter(businessCanvasData[p3Section]) }}
              ></Box>
            </Box>
          ))
        ) : (
          <Box>No data available.</Box>
        )}
      
      </Box>
    </div>
  );
}

export default CheckIPForm;
