import { Box, Typography } from "@mui/material";
import BusinessCanvas from "./BusinessCanvas";
const today = new Date();
const day = String(today.getDate()).padStart(2, '0');
const month = String(today.getMonth() + 1).padStart(2, '0');
const year = today.getFullYear();
const formattedDate = `${day}/${month}/${year}`;
const ConfirmationForm = ({ prevStep, formData, isLastStep, componentRef, businessCanvasData }) => {
    return (
        <div className="container">
            <Box component="div" ref={componentRef} id="business-canvas-container">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: "bold", fontSize: "1rem" }}
                    >
                        The Business Model Canvas:
                    </Typography>
                    <Box
                        sx={{
                            display: { xs: "block", md: "flex" },
                            gap: { xs: 0, md: "20px" },
                        }}
                    >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Designed for: HUGEIDEA
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Designed by: Bao Tran
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Date: {formattedDate}
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Version: 1.0
                        </Typography>
                    </Box>
                </Box>
                <BusinessCanvas data={businessCanvasData} />
            </Box>
        </div>
    );
};

export default ConfirmationForm;
